import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';
import QueryEditor from './queryeditor';
import ResultsTable from './resultstable';
import StatusDisplay from './statusdisplay';
import Header from './header';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

function Home() {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [selectedDatabase, setSelectedDatabase] = useState('Agroweb-Kire');
    const [selectedRuntype, setSelectedRuntype] = useState('All');
    const [country, setCountry] = useState('');
    const [project, setProject] = useState('');
    const [runname, setRunName] = useState('');
    const [rule_ids, setRuleId] = useState('');
    const [query, setQuery] = useState('');
    const [queryResult, setQueryResult] = useState([]);
    const [queryStatus, setQueryStatus] = useState('');
    const [exportStatus, setExportStatus] = useState('');
    const [isQueryEditorVisible, setIsQueryEditorVisible] = useState(false);

    const userName = sessionStorage.getItem('userName');

    useEffect(() => {
        const token = sessionStorage.getItem('accessToken');
        if (!token || !userName) {
            navigate('/auth');
        } else {
            setIsAuthenticated(true);
            setAccessToken(token);
        }
    }, [navigate, userName]);

    useEffect(() => {
        const generateQuery = () => {
            let dynamicQuery = '';
            if (selectedRuntype === 'Country') {
                dynamicQuery += ` run_name LIKE '%Type:Country%'`;
            } else if (selectedRuntype === 'Project') {
                dynamicQuery += ` run_name LIKE '%Type:Project%'`;
            } else if (selectedRuntype === 'System') {
                dynamicQuery += ` run_name LIKE '%Type:System%'`;
            } else if (selectedRuntype === 'Technical') {
                dynamicQuery += ` run_name LIKE '%Type:Technical%'`;
            } 
            
            if (country){
                if (dynamicQuery) dynamicQuery += ` AND `;
                dynamicQuery += `run_name LIKE '%Country:${country}%'`;
            }
            if (project) {
                if (dynamicQuery) dynamicQuery += ` AND `;
                dynamicQuery += `run_name LIKE '%Project:%${project}%'`;
            }
                
            if (runname) {
                if (dynamicQuery) dynamicQuery += ` AND `;
                dynamicQuery += ` run_name LIKE '%${runname}%'`;
            }
            if (rule_ids) {
                if (dynamicQuery) dynamicQuery += ` AND `;
                const ruleIdsArray = rule_ids.split(',').map(id => id.trim()).join(', ');
                dynamicQuery += ` rule_id_in_registry IN (${ruleIdsArray})`;
            }

            if(dynamicQuery){
                dynamicQuery = `WHERE ${dynamicQuery}`;
            }

           let baseQuery =  `WITH LatestRunDates AS (
                SELECT LEFT(run_name,charindex('History',run_name)) AS run_name_short
                    ,MAX(run_time) AS max_run_time
                    ,rule_id_in_registry
                FROM ${selectedDatabase}-Rule-Results ${dynamicQuery}
                GROUP BY LEFT(run_name,charindex('History',run_name))
                    ,rule_id_in_registry
            )
            SELECT r.run_time, [result_query], [run_name], [run_date], [field_name], r.[rule_id_in_registry] as rule_id, [number_of_discrepancies], [rule_description]
            FROM ${selectedDatabase}-Rule-Results r
            INNER JOIN LatestRunDates lrd ON lrd.run_name_short = LEFT(r.run_name,charindex('History',r.run_name))
                    AND r.run_time = lrd.max_run_time
                    AND r.rule_id_in_registry = lrd.rule_id_in_registry
            WHERE number_of_discrepancies > 0` 

            return baseQuery;
        };

        setQuery(generateQuery());
    }, [selectedDatabase, selectedRuntype, country, project, runname, rule_ids]);

    useEffect(() => {
        // Update the selectedRuntype based on the selectedDatabase
        if (selectedDatabase === 'Agroweb-Kire') {
            setSelectedRuntype('All');
        } else {
            setSelectedRuntype('Technical');
        }
    }, [selectedDatabase]);

    const handleCustomQueryExecution = (data) => {
        setQueryResult(data);
        setQueryStatus(data.length ? '' : 'No results returned');
    };

    const executeQuery = () => {
        console.log("Run Type: " + selectedRuntype);
        setExportStatus('');
        setQueryStatus('Executing Query...');
        fetch(`${apiUrl}/ExecuteQuery`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query, database: selectedDatabase + '-Rule-Results' })
        })
        .then(response => {
            if (response.status === 401) {
                throw new Error('Your session has expired. Please log in again.');
            }
            if (!response.ok) {
                throw new Error('Failed to execute query');
            }
            return response.json();
        })
        .then(data => {
            setQueryResult(data);
            setQueryStatus(data.length ? '' : 'No results returned');
        })
        .catch(error => {
            console.error('Error executing query:', error);
            setQueryResult([]);
            setQueryStatus(`Error executing query: ${error.message}`);
            if (error.message.includes('session has expired')) {
                alert('Your session has expired. Please log in again.');
                navigate('/auth'); // Redirect to the Auth page
            }
        });
    };
    
    const toggleQueryEditor = () => {
        setIsQueryEditorVisible(prev => !prev);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/auth');
    };

    return (
        <div>
            <Header userName={userName} handleLogout={handleLogout} />
            <div className="content">
                <div className="top-controls">
                    <div className="form-group">
                        <label className="filter-label">Database</label>
                        <select
                            value={selectedDatabase}
                            onChange={e => setSelectedDatabase(e.target.value)}
                            disabled={!isAuthenticated}
                        >
                            <option value="Agroweb-Kire">AgroWeb Kire</option>
                            <option value="Quesmanto">Quesmanto</option>
                        </select>
                    </div>

                    {selectedDatabase === 'Agroweb-Kire' ? (
                        <div className="form-group">
                            <label className="filter-label">Run Type</label>
                            <select
                                value={selectedRuntype}
                                onChange={e => setSelectedRuntype(e.target.value)}
                                disabled={!isAuthenticated}
                            >
                                <option value="All">All</option>
                                <option value="Country">Country</option>
                                <option value="Project">Project</option>
                                <option value="System">System</option>
                            </select>
                        </div>
                    ) : (
                        <div className="form-group">
                            <label className="filter-label">Run Type</label>
                            <select
                                value={selectedRuntype}
                                onChange={e => setSelectedRuntype(e.target.value)}
                                disabled={!isAuthenticated}
                            >
                                <option value="Technical">Technical</option>
                            </select>
                        </div>
                    )}

                    <div className="form-group">
                        <label className="filter-label">Country</label>
                        <input
                            type="text"
                            placeholder="Country"
                            value={country}
                            onChange={e => setCountry(e.target.value)}
                            disabled={!isAuthenticated}
                        />
                    </div>

                    <div className="form-group">
                        <label className="filter-label">Project</label>
                        <input
                            type="text"
                            placeholder="Project (partial accepted)"
                            value={project}
                            onChange={e => setProject(e.target.value)}
                            disabled={!isAuthenticated}
                        />
                    </div>

                    <div className="form-group">
                        <label className="filter-label">Run Name</label>
                        <input
                            type="text"
                            placeholder="Run Name (partial accepted)"
                            value={runname}
                            onChange={e => setRunName(e.target.value)}
                            disabled={!isAuthenticated}
                        />
                    </div>

                    <div className="form-group">
                        <label className="filter-label">Rule Ids</label>
                        <input
                            type="text"
                            placeholder="Rule Ids (comma separated)"
                            value={rule_ids}
                            onChange={e => setRuleId(e.target.value)}
                            disabled={!isAuthenticated}
                        />
                    </div>

                    <button onClick={executeQuery} disabled={!isAuthenticated || !query}>Submit</button>
                </div>
            </div>
            <div>
                <br/> <br/>
                <button onClick={toggleQueryEditor} style={{ display: 'none' }}>
                    {isQueryEditorVisible ? 'Hide Query Editor' : 'Show Query Editor'}
                </button>
            </div>
            {isQueryEditorVisible && <QueryEditor
                isAuthenticated={isAuthenticated}
                accessToken={accessToken}
                query={query}
                setQuery={setQuery}
                onExecuteCustomQuery={handleCustomQueryExecution}
                selectedDatabase={selectedDatabase}
            />}
            <StatusDisplay statusMessage={exportStatus} />
            <ResultsTable
                isAuthenticated={isAuthenticated}
                accessToken={accessToken}
                queryResult={queryResult}
                queryStatus={queryStatus}
                setExportStatus={setExportStatus}
                selectedDatabase={selectedDatabase}
            />
        </div>
    );
}

export default Home;
